import SliderEmbla from 'components/Slider/SliderEmbla/SliderEmbla';
import { SlidesObject } from 'components/Slider/SliderEmbla/SliderEmbla.styled';
import { BREAKPOINTS } from 'constants/breakpoints';
import { EmblaOptionsType } from 'embla-carousel';
import breakpointValues from 'theme/variables/breakpoints';
import { IBanner, IGroupingTileCarousel, ITileProduct } from 'types/ContentfulTypes';
import ContentfulCarouselBannerCard from '../ContentfulCarouselBannerCard/ContentfulCarouselBannerCard';
import ContentfulGroupingHeading from '../ContentfulGroupingHeading/ContentfulGroupingHeading';
import ContentfulGroupingWrapper from '../ContentfulGroupingWrapper/ContentfulGroupingWrapper';
import ContentfulProductBannerCard from '../ContentfulProductBannerCard/ContentfulProductBannerCard';
import { CONTENTFUL_TYPES } from '../types';

interface ContentfulGroupingTileCarouselProps {
  className?: string;
  content: IGroupingTileCarousel;
}

const ContentfulGroupingTileCarousel = ({ className, content }: ContentfulGroupingTileCarouselProps) => {
  if (!content?.fields) {
    return null;
  }

  const { items, title } = content.fields;

  const options: EmblaOptionsType = {
    breakpoints: {
      [`(min-width: ${breakpointValues.md}px)`]: { slidesToScroll: 3 },
      [`(min-width: ${breakpointValues.lg}px)`]: { slidesToScroll: 4 },
      [`(min-width: ${breakpointValues.xl}px)`]: { slidesToScroll: 5 },
    },
  };

  const showDots = { [BREAKPOINTS.XS]: false };
  const showArrows = { [BREAKPOINTS.MD]: true };
  const slidesToShow: SlidesObject = {
    [BREAKPOINTS.XS]: 1.5,
    [BREAKPOINTS.MD]: 3,
    [BREAKPOINTS.LG]: 4,
    [BREAKPOINTS.XL]: 5,
  };

  return (
    <ContentfulGroupingWrapper className={className}>
      {title && <ContentfulGroupingHeading>{title}</ContentfulGroupingHeading>}
      {items?.length > 0 && (
        <SliderEmbla slidesToShow={slidesToShow} showArrows={showArrows} showDots={showDots} options={options}>
          {items?.map((item) => {
            const contentType = item?.sys?.contentType?.sys?.id;
            const imageMinHeight = '220px';

            if (contentType === CONTENTFUL_TYPES.BANNER) {
              return (
                <ContentfulCarouselBannerCard
                  key={item.sys.id}
                  content={item as IBanner}
                  imageMinHeight={imageMinHeight}
                  scaleContent
                />
              );
            }

            if (contentType === CONTENTFUL_TYPES.TILE_PRODUCT) {
              return (
                <ContentfulProductBannerCard
                  key={item.sys.id}
                  content={item as ITileProduct}
                  imageMinHeight={imageMinHeight}
                  scaleContent
                />
              );
            }

            return null;
          })}
        </SliderEmbla>
      )}
    </ContentfulGroupingWrapper>
  );
};

export default ContentfulGroupingTileCarousel;
