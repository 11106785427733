import Typography from 'components/Typography/Typography';
import { BannerContentLink, BannerCardImage, BannerCardImageProps } from './ContentfulCarouselBannerCard.styled';
import Flex from 'components/Flex/Flex';
import { Spacer } from 'constants/styling';
import ContentfulBannerContentBlock from '../ContentfulBannerContentBlock/ContentfulBannerContentBlock';
import { IBanner } from 'types/ContentfulTypes';

interface ContentfulCarouselBannerCardProps extends BannerCardImageProps {
  border?: 'default' | 'none';
  borderRadius?: Spacer;
  className?: string;
  content: IBanner;
  scaleContent?: boolean;
}

const ContentfulCarouselBannerCard = ({
  border = 'default',
  borderRadius = 25,
  className,
  content,
  imageMinHeight = '200px',
  scaleContent,
}: ContentfulCarouselBannerCardProps) => {
  const { button, mediumImage, mobileImage, title, wideImage } = content?.fields;

  const link = button?.fields?.link;
  const label = button?.fields?.label;

  return (
    <Flex border={border} borderRadius={borderRadius} className={className} flexDirection="column" fullHeight>
      <BannerCardImage
        imageMinHeight={imageMinHeight}
        mediumImage={mediumImage}
        mobileImage={mobileImage}
        wideImage={wideImage}
      />
      {(title || link) && (
        <ContentfulBannerContentBlock fullHeight={scaleContent}>
          {title && (
            <Typography fontWeight="bold" maxLines={2}>
              {title}
            </Typography>
          )}
          {link && (
            <BannerContentLink link={link}>
              <Typography color="" fontSize={88}>
                {label}
              </Typography>
            </BannerContentLink>
          )}
        </ContentfulBannerContentBlock>
      )}
    </Flex>
  );
};

export default ContentfulCarouselBannerCard;
