import styled from 'styled-components';
import { color } from 'theme';
import { handleStylingValue } from 'utils/textUtil';
import ContentfulImageBlockResponsive from '../ContentfulImageBlockResponsive/ContentfulImageBlockResponsive';
import ContentfulPageLink from '../ContentfulPageLink/ContentfulPageLink';

export interface BannerCardImageProps {
  imageMinHeight?: string | number;
}

export const BannerCardImage = styled(ContentfulImageBlockResponsive)<BannerCardImageProps>`
  height: 100%;
  min-height: ${({ imageMinHeight }) => imageMinHeight && handleStylingValue(imageMinHeight)};
  position: relative;
`;

export const BannerContentLink = styled(ContentfulPageLink)`
  && {
    text-decoration: underline;
    color: ${color('primary-300')};

    &:hover {
      color: ${color('primary-400')};
    }
  }
`;
